<script>
import { orderCategories } from '@/misc.js';

export default {
  name: 'filter-bar',

  props: {
    category: {
      type: Object,
      required: true,
    },

    limit: {
      type: Number,
      default: 20,
    },
  },

  data() {
    let imagesPerPage = 20;
    if (localStorage.getItem('imagesPerPage')) {
      imagesPerPage = +localStorage.getItem('imagesPerPage');
    }
    return {
      isExpanded: false,
      imagesPerPage,
    };
  },

  computed: {
    filteredCategories() {
      return orderCategories(this.category.children
        .filter(c => c.images_count > 0));
    },
  },

  watch: {
    imagesPerPage(val) {
      localStorage.setItem('imagesPerPage', val);
      this.$emit('update:limit', val);
    },
  },

  methods: {

    /**
     * Note:
     * These functions are used instead of just height
     * to be able to animate the height of the list.
     */
    expand() {
      /**
       * Note:
       * 120 is to be sure to expand all the content,
       * each row is, 40 font-height + padding, 120 should manage even
       * if a category has very long text.
       */
      const maxHeight = this.filteredCategories.length * 120;
      const el = this.$refs.subCategoriesList;
      el.style.maxHeight = `${maxHeight}px`;

      this.isExpanded = true;
    },

    collapse() {
      const el = this.$refs.subCategoriesList;
      el.style.maxHeight = '0px';

      this.isExpanded = false;
    },
  },
};
</script>

<template>
  <div class="filterbar h-full flex flex-col py-4 bg-gray-200 md:bg-gray-100 p-10">
    <div class="w-full text-left py-2 flex justify-between">
      <p>{{ $t('imagesPerPage') }}</p>
      <select v-model="imagesPerPage">
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>

    <div class="w-full flex justify-between text-md text-left py-2 font-bold" v-if="filteredCategories.length > 0">
      <span
        id="limitation-text"
        class="block mr-16"
        v-if="category.limitation_info_sv"
        v-html="category.limitation_info_sv"
      />
      <span class="block mr-16" v-else>
        {{ $t('library.limit_search') }}
      </span>
      <button @click="expand" v-if="!isExpanded">
        <i class="fas fa-chevron-down"></i>
      </button>
      <button @click="collapse" v-else>
        <i class="fas fa-chevron-up"></i>
      </button>
    </div>

    <div
      v-if="filteredCategories"
      id="sub-categories"
      ref="subCategoriesList"
      :class="['text-left', 'overflow-hidden', 'max-h-0']"
    >
      <localized-link
        class="block py-2 hover:underline"
        :to="{ name: 'library', params: { category: $i18n.locale == 'en' ? category.full_path_en.toLowerCase().split('/') : category.full_path_sv.toLowerCase().split('/') }  }"
        v-for="(category, i) in filteredCategories"
        :key="i"
      >
        {{ $i18n.locale == 'en' ? category.title_en :  category.title_sv }} ({{ category.images_count }})
      </localized-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#sub-categories {
  transition: max-height .2s ease-in;
}

 #limitation-text > a {
  color: #3b82f6;
  text-decoration: underline;
}
</style>

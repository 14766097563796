<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { translateLink } from '@/misc';
import Gallery from '@/components/gallery/Gallery.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import FilterBar from './components/FilterBar.vue';
import MobileFilterBar from './components/MobileFilterBar.vue';
import {i18n} from "vue-lang-router";

export default {
  name: 'library',

  data() {
    return {
      searchInitialized: false,
      category: null,
      error: null,
      pageInfo: null,
      limit: +localStorage.getItem('imagesPerPage') || 20,
      isCreating: false,
    };
  },

  async created() {
    // isCreating makes sure that some watchers wait until
    // the component is initialized before starting.
    this.isCreating = true;
    const cat = this.$route.params.category;
    const page = this.$route.query.page || 1;
    this.limit = +localStorage.getItem('imagesPerPage') || 20;

    if (cat) {
      await this.fetchByCategory(cat, page, this.limit);
      this.isCreating = false;
    } else {
      this.$router.push({ name: 'library-start' });
    }
  },

  computed: {
    ...mapGetters(['isLoading']),

    categoryMode() {
      return this.category != null;
    },

    images() {
      return this.pageInfo ? this.pageInfo.data : [];
    },

    currentPage() {
      return parseInt(this.$route.query.page, 10) || 1;
    },

    nearestAncestor() {
      if (this.category.ancestors.length === 0) {
        return null;
      }

      return this.category.ancestors[this.category.ancestors.length - 1];
    },
  },

  watch: {
    category(val) {
      if (val.info_sv) {
        this.translateInfo(val);
      }

      if (val.limitation_info_sv) {
        this.translateLimitationInfo(val);
      }
    },

    limit() {
      if (this.isCreating) return;

      this.$router.replace({
        path: this.$route.path,
        query: {
          limit: +localStorage.getItem('imagesPerPage'),
        },
      });
    },
  },

  methods: {
    fetchByCategory(cat, page, limit) {
      Promise.all([
        axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/category`, {path: cat}),
        axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/image/category?page=${page}&limit=${limit}`, {path: cat}),
      ]).then((res) => {
        this.category = res[0].data;
        const searchResult = res[1].data;

        this.pageInfo = searchResult;
        document.title = i18n.locale == 'en' ? this.category.web_title_en  + " | " + i18n.t("tempus") : this.category.web_title_sv  + " | " + i18n.t("tempus");
      });
    },

    translateInfo(category) {
      translateLink(category.info_sv).then((link) => {
        this.category.info_sv = link;
      });
    },

    translateLimitationInfo(category) {
      translateLink(category.limitation_info_sv).then((link) => {
        this.category.limitation_info_sv = link;
      });
    },

    prevPage() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: this.currentPage - 1,
        },
      });
    },

    nextPage() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: this.currentPage + 1,
        },
      });
    },

    getPluralSingular() {
      return this.category.images_count > 1 ? 'bilder' : 'bild';
    },
  },

  components: {
    FilterBar,
    Gallery,
    Breadcrumbs,
    MobileFilterBar,
  },
};
</script>

<template>
  <div class="flex flex-col md:flex-row">
    <div class="hidden md:block md:max-w-xs">
      <filter-bar
        v-if="category && category.children"
        :category="category"
        :limit.sync="limit"
      />
    </div>

    <div class="h-full w-full md:hidden">
      <MobileFilterBar
        v-if="category && category.children"
        :category="category"
        :limit.sync="limit"
      />
    </div>

    <div class="flex-1 bg-white">
      <div class="flex flex-col justify-between text-left p-10" v-if="category">
        <div class="hidden md:block">
          <breadcrumbs v-model="category" :light-mode="false" />
        </div>

        <div>
          <div>
            <localized-link
              v-if="category.ancestors.length > 0"
              tag="button"
              :to="{ name: 'library', params: { category: $i18n.locale == 'en' ? nearestAncestor.full_path_en.toLowerCase().split('/') : nearestAncestor.full_path_sv.toLowerCase().split('/') }  }"
              class="block md:hidden w-full h-10 px-4 mb-6 rounded bg-blue-500 hover:bg-blue-700 text-white"
            >
              <span class="font-bold mr-4">Upp en kategori</span>
              <i class="fas fa-level-up-alt"></i>
            </localized-link>
          </div>

          <div>
            <h1 class="categoriTitle"> {{ $i18n.locale == 'en' ? category.web_title_en :  category.web_title_sv }}</h1>
            <p class="categoriParagraph">
              {{ $t("categoryHas") }} {{ category.images_count }} {{ $tc("image", category.images_count) }}.
            </p>
          </div>
        </div>
        <span class="text-sm info-text" v-html="$i18n.locale == 'en' ? category.info_en :  category.info_sv"></span>
      </div>

      <gallery class="gallery-view w-full" v-model="images"></gallery>

      <div class="page-handle flex justify-center my-8" v-if="pageInfo">
        <button
          class="text-2xl"
          :disabled="pageInfo.current_page == 1"
          @click="prevPage()"
        >
          <i class="fas fa-chevron-left prev-page-icon"></i>
        </button>
        <div class="info text-2xl mx-4 inline-block">
          {{ pageInfo.current_page }} av {{ pageInfo.last_page }}
        </div>
        <button
          class="text-2xl"
          :disabled="pageInfo.current_page == pageInfo.last_page"
          @click="nextPage()"
        >
          <i class="fas fa-chevron-right next-page-icon"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.info-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 29px;

   a {
    color: #4299e1 !important;
    text-decoration: underline !important;
  }
}

.categoriTitle{
  font-size: 30px;
        font-weight: 500;
        line-height: 36px;
        margin-bottom: 12px;
}

.categoriParagraph{
  font-size: 16px;
            font-weight: 500;
            line-height: 29px;
}
</style>

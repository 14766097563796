<script>
import { orderCategories } from '@/misc.js';

export default {
  name: 'filter-bar',

  props: {
    category: {
      type: Object,
      required: true,
    },

    limit: {
      type: Number,
      default: +localStorage.getItem('imagesPerPage') || 20,
    },
  },

  data() {
    return {
      tags: [],
      imagesPerPage: +localStorage.getItem('imagesPerPage') || 20,
    };
  },

  computed: {
    filteredCategories() {
      return orderCategories(this.category.children
        .filter(c => c.images_count > 0));
    },
  },

  watch: {
    imagesPerPage(val) {
      localStorage.setItem('imagesPerPage', val);
      this.$emit('update:limit', val);
    },
  },

  methods: {
    hide() {
      this.$emit('onHide');
    },
  },
};
</script>

<template>
  <div class="filterbar h-full flex flex-col py-4 bg-gray-200 md:bg-gray-100 p-10">
    <div class="w-full text-left py-2 flex justify-between">
      <p class="imagesPerPage">{{ $t('imagesPerPage') }}</p>
      <select v-model="imagesPerPage">
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>

    <div class="text-sm text-left py-2 imagesPerPage">
      {{ $i18n.locale == 'en' ? category.limitation_info_en :  category.limitation_info_sv }}
    </div>

    <div class="text-left" v-if="filteredCategories">
      <localized-link
        class="block py-2 hover:underline filterCount"
        :to="{ name: 'library', params: { category: $i18n.locale == 'en' ? category.full_path_en.toLowerCase().split('/') : category.full_path_sv.toLowerCase().split('/') }  }"
        v-for="(category, i) in filteredCategories"
        :key="i"
      >
        {{ $i18n.locale == 'en' ? category.title_en :  category.title_sv }} ({{ category.images_count }})
      </localized-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .imagesPerPage{
    font-size: 16px;
    font-weight: 500;
    line-height: 29px;
  }

  .filterCount{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 7px 0;
  }
</style>